<template lang="html">
  <div class="Date">
    <div class="picker" v-on:click="openDatePicker()">
      <input type="text" v-model="brokenDownDate.day" class="picker single" readonly>   /
      <input type="text" v-model="brokenDownDate.month" class="picker single" readonly> /
      <input type="text" v-model="brokenDownDate.year" class="picker" readonly>
    </div>
    <i class="fa fa-calendar" v-on:click="openDatePicker()"></i>
    <datepicker
      v-show="showDatePicker"
      inline
      ref="datepicker"
      class="datepickerExecution"
      monday-first
      v-model="answer"
      @selected="addAnswer($event)"
      :language="lang"></datepicker>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import Datepicker from 'vuejs3-datepicker';
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
  name: 'Date',

  props: {
    question:{
      type: Object,
      default: null
    },
  },
  components:{
    Datepicker,
  },
  data() {
    return {
      answer:null,
      brokenDownDate:{
        day:null,
        month:null,
        year:null,
      },
      lang:'es'
    }
  },
  methods:{
    addAnswer(e){
      this.answer = e
      let date = new Date(this.answer)
      this.brokenDownDate.day = date.getDate() < 10 ? "0"+date.getDate() : date.getDate()
      this.brokenDownDate.month = date.getMonth() + 1 < 10 ? "0"+(date.getMonth()+1) : date.getMonth()+1
      this.brokenDownDate.year = date.getFullYear()
      this.$emit('send-answers', {answer:{date:this.brokenDownDate.year+"-"+this.brokenDownDate.month+"-"+this.brokenDownDate.day},question:this.question, answerValid:true})
      this.closeDatePicker()
    }
  },
  async mounted(){
    this.lang = navigator.language.split('-')[0];
    if (this.question.answer) {
      this.answer = new Date(this.question.answer.date)
      this.addAnswer()
    }
  },
  setup() {
    const datepicker = ref(null)
    const showDatePicker = ref(false)
    onClickOutside(datepicker, (event) => closeDatePicker())
    const closeDatePicker = () => {
      showDatePicker.value = false
    }
    const openDatePicker = () => {
      showDatePicker.value = true
    }
    return { datepicker, showDatePicker, closeDatePicker, openDatePicker }
  }
})
</script>

<style lang="scss" scoped>
.Date{
  display: flex;
  align-items: center;
  .picker{
    display: flex;
    font-size: 30px;
    color: #aeaeae;
    margin-right: 10px;
    &.single{
      width: 40px;
    }
  }
  input{
    outline: none;
    border: none;
    border-bottom: 1px solid #949494;
    font-weight: bold;
    width: 100px;
    text-align: center;
    background-color: white;
  }
  i.fa-calendar{
    background: -webkit-gradient(linear, left top, left bottom, from(var(--main-color)), to(var(--secondary-color)));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 1px solid #e4e4e4;
    box-shadow: -2px 2px 0.96px 0.04px rgb(0 0 0 / 5%);
    height: 37px;
    width: 37px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
  }
  @media only screen and ( max-width: 769px ) {
    input{
      background: transparent;
    }

    .picker{
      font-size: 25px;
      &.single{
        width: 58px;
      }
    }
  }
}
</style>
<style lang="scss">
.datepickerExecution{
  position: absolute !important;
  inset: 0;
  .vuejs3-datepicker__calendar-topbar, .cell.day.selected{
    background: #aeaeae;
  }
}
</style>
