
import * as yup from 'yup'
import { useField } from 'vee-validate'
import i18n from '@/i18n'

import {
    defineComponent,
    ref,
    Ref,
    inject,
    ComputedRef,
    computed,
    onBeforeMount,
    onMounted
} from 'vue'
import {
    IonContent,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonPage,
    IonInput,
    IonItem,
    IonLabel,
    IonButton,
    IonCheckbox,
    IonFooter,
} from '@ionic/vue'
import { useRouter, useRoute } from 'vue-router'
import RegisterAdvantages from '@/components/RegisterAdvantages.vue'
import DesktopRegisterAdvantages from '@/components/DesktopRegisterAdvantages.vue'
import TermsAndConditions from '@/components/termsAndConditions.vue'
import Swal from 'sweetalert2'

const componentName = 'Register'
const components = {
    IonContent,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonPage,
    IonInput,
    IonItem,
    IonLabel,
    IonButton,
    IonCheckbox,
    IonFooter,
    RegisterAdvantages,
    DesktopRegisterAdvantages,
    TermsAndConditions
}

const setup = () => {
    const success: Ref<boolean> = ref(false)
    const showTerms: Ref<boolean> = ref(false)
    const error: Ref<boolean> = ref(false)
    const errormsg: Ref<string> = ref('')
    // const privacy: Ref<boolean> = ref(false)
    const tos: Ref<boolean> = ref(false)
    const prefix = ref(false);
    const authStore: any = inject('authState')
    const validPrefixList = computed(() => authStore.state.validPrefixList)

    const signUp = computed(() => authStore.state.signUp)

    const showRegisterAdvantages = ref(false)
    const loading = ref(false)

    onBeforeMount(async () => {
        await authStore.obtainCountry()
        setPrefixDefault();
    })

    // TODO: Extract validators to their own file
    const { errorMessage: nameError, value: name } = useField<string>(
        'name',
        yup.string().required(i18n.global.t('FIELD_REQUIRED'))
    )
    const { errorMessage: surnameError, value: surname } = useField<string>(
        'surname',
        yup.string().required(i18n.global.t('FIELD_REQUIRED'))
    )
    const { errorMessage: mailError, value: email } = useField<string>(
        'email',
        yup
            .string()
            .email(i18n.global.t('WRONG_EMAIL_FORMAT'))
            .required(i18n.global.t('FIELD_REQUIRED'))
    )
    const { errorMessage: passwordError, value: password } = useField<string>(
        'password',
        yup
            .string()
            .min(6, i18n.global.t('WRONG_PASSWORD_FORMAT'))
            .required(i18n.global.t('FIELD_REQUIRED'))
    )
    const { errorMessage: phoneError, value: phone } = useField<string>(
        'phone',
        yup
            .string()
            .required(i18n.global.t('FIELD_REQUIRED'))
            .matches(/^[0-9]+$/, i18n.global.t('WRONG_PHONE_FROMAT'))
            .min(9, i18n.global.t('WRONG_PHONE_FROMAT'))
            .max(9, i18n.global.t('WRONG_PHONE_FROMAT'))
    )

    const formValid: ComputedRef<boolean> = computed(
        (): boolean =>
            !!name.value &&
            !nameError.value &&
            !!surname.value &&
            !surnameError.value &&
            !!email.value &&
            !mailError.value &&
            !!password.value &&
            !passwordError.value &&
            !!phone.value &&
            !phoneError.value &&
            tos.value
            // privacy.value &&
    )

    const router: any = useRouter()
    const route: any = useRoute()

    const setPrefixDefault = async() => {
      prefix.value = authStore.state.userData.mobilePrefix
    }

    const handleShowTerms = () => {
        showTerms.value = false
    }

    const register = async () => {
        try {
            if (!formValid.value) return
            
            success.value = false
            error.value = false
            errormsg.value = ''
            loading.value=true

        const data = {
            email: email.value,
            password: password.value,
            name: name.value,
            surname1: surname.value,
            residenceCountry: authStore.state.userData.residenceCountry,
            language: navigator.language.split("-")[0],
            mobile: phone.value,
            // TODO: Make the following properties' values dynamic
            invitationCode: '',
            referralCode: '',
            invitedBy: '',
            externalUserId: '',
            prefix: prefix.value,
            device: navigator.userAgent
        }

        if (route.query.invitationCode != undefined) {
            data.invitationCode = route.query.invitationCode
        }
        if (route.query.referral != undefined) {
            data.referralCode = route.query.referral
        }
        if (route.query.invitedBy != undefined) {
            data.invitedBy = route.query.invitedBy
        }
        if(route.query.user_id != undefined) {
            data.externalUserId = route.query.user_id
        }
        if(route.query.tduid != undefined) { // user id for Tradedoubler
            authStore.state.tduid = route.query.tduid
        }

        const signedUp = await authStore.signUp(data)

        if (signedUp.showError) {
            console.log('error singingUp')
            console.log(signedUp.errorMessage)
            Swal.fire({
                icon: 'error',
                title: signedUp.errorMessage,
            })
            return 
        }
        const payload = {
            email: email.value,
            password: password.value,
            appVersion: ''
        }
        const loggedIn = await authStore.logUserIn(payload)

        if (!loggedIn.success) {
            Swal.fire({
                icon: 'error',
                title: loggedIn.message,
            })
            return
        }
    } catch (error) {
        console.log(error)
        loading.value= false

    }finally{
        router.push({ name: 'how', replace: true })
        loading.value=false
        }
        
    }

    return {
        name,
        nameError,
        surname,
        surnameError,
        email,
        mailError,
        password,
        passwordError,
        phone,
        prefix,
        phoneError,
        // privacy,
        tos,
        error,
        errormsg,
        formValid,
        register,
        signUp,
        showRegisterAdvantages,
        validPrefixList,
        loading,
        showTerms,
        handleShowTerms,
    }
}

export default defineComponent({ name: componentName, components, setup })
