
import * as yup from 'yup'
import { useField } from 'vee-validate'
import { useRouter } from 'vue-router'
import { defineComponent, ref, Ref, inject, ComputedRef, computed } from 'vue'
import {
    IonContent,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonPage,
    IonInput,
    IonItem,
    IonLabel,
    IonButton,
} from '@ionic/vue'
import Swal from 'sweetalert2';
import { useI18n } from "vue-i18n";
import i18n from '@/i18n';
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';
import { AppVersion } from '@awesome-cordova-plugins/app-version';

const componentName = 'Login'
const components = {
    IonContent,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonPage,
    IonInput,
    IonItem,
    IonLabel,
    IonButton,
}
const setup = () => {
    const auth: any = inject('authState')
    const router = useRouter()
    const error: Ref<boolean> = ref(false)
    const errormsg: Ref<string> = ref('')
    const loading = ref(false)
    const appVersion = ref('')

    const { errorMessage: errorMail, value: email } = useField<string>(
        'email',
        yup
            .string()
            .email(() => i18n.global.t('tester_form_valid_email'))
            .required(() => i18n.global.t('tester_required_email'))
    )
    const { errorMessage: errorPwd, value: password } = useField<string>(
        'password',
        yup.string().required(() => i18n.global.t('tester_required_password'))
    )
    const formValid: ComputedRef<boolean> = computed(
        (): boolean =>
            !!email.value &&
            !!password.value &&
            !errorMail.value &&
            !errorPwd.value
    )

    const login = async () => {
        try {
            if (!formValid.value) return
            loading.value= true
            if (Capacitor.isNativePlatform()) {
                AppVersion.getVersionNumber().then((appversion: any) => {
                    appVersion.value = appversion
                })
            }
            const payload = {
                email: email.value,
                password: password.value,
                appVersion: appVersion.value
            }

            const loggedIn = await auth.logUserIn(payload)
            if (!loggedIn.success) {
                Swal.fire({
                    icon: 'error',
                    title: loggedIn.message,
                })
                loading.value = false
                return
            }
            else if(loggedIn.nextStep == 'testWall'){
                router.push({ name: 'tests.index' })
            }
            else if(loggedIn.nextStep == 'validate2fa'){
                await Preferences.set({
                    key: 'needs2FA',
                    value: Date.now().toString(),
                })
                router.push({ name: 'validate2fa' })
            }
            else if(loggedIn.nextStep == 'add-mobile'){
                router.push({ name: 'add-mobile' })
            }
            else if(loggedIn.nextStep == 'unique-validation'){
                router.push({ name: 'unique-validation' })
            }
            setTimeout(() => {
                loading.value = false
            }, 1000)
        } catch (error) {
            loading.value = false
        }
            
    }
    const getCurrentYear = computed (() => {
        return new Date().getFullYear();
    })
    return {
        email,
        password,
        error,
        errormsg,
        login,
        errorMail,
        errorPwd,
        formValid,
        loading,
        getCurrentYear,
    }
}

export default defineComponent({ name: componentName, components, setup })
