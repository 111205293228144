import { createRouter, createWebHistory } from '@ionic/vue-router'
import routes from './routes'
import authStore from '@/store/auth.store'
import { NavigationGuardNext, RouteLocationNormalized, RouteLocationRaw } from 'vue-router'
import { loadCitiServiPixel } from '../../../commonvue/src/assets/scripts/citiservipixel'
import axios from '../config/axiosConfig'
import { Preferences } from '@capacitor/preferences';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

type AuthData = {
    isLoggedIn: boolean
    appToken: string
    authToken: string
}

const check2FA = async () => {
    const needs2FA = await Preferences.get({ key: 'needs2FA' })

    if (!needs2FA.value) return needs2FA

    const currentTimeStamp = Date.now()
    const maxMillisecondsValid = 5 * 60 * 1000

    if (currentTimeStamp > Number(needs2FA.value) + maxMillisecondsValid) {
        await Preferences.remove({ key: 'needs2FA' })
        await authStore.logUserOut()

        return Preferences.get({ key: 'needs2FA' })
    }

    return needs2FA
}

router.beforeEach(
    async (
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: NavigationGuardNext
    ) => {
        console.log(to.path)
        if (to.hash.indexOf('#/testVue/')>-1){
            if (to.hash.indexOf('?')>-1){
                const params = to.hash.substring(to.hash.indexOf('?')+1)
                var testId = to.hash.replace('#/testVue/','')
                testId = testId.substring(0,testId.indexOf('?'))
                console.log('params '+params)
                next('app/test/'+testId+'/show?'+params)
            } else {
                const testId = to.hash.replace('#/testVue/','').substring(0,to.hash.indexOf('?'))
                console.log('Detected old test url, proceeding to redirect to /app/test/'+testId+'/show')
                next('app/test/'+testId+'/show')
            }
        } else if (to.hash.indexOf('#/entrar')>-1){
            if (to.hash.indexOf('?')>-1) {
                const params = to.hash.substring(to.hash.indexOf('?')+1)
                next('entrar?'+params)
            } else {
                next()
            }
        } else if (to.hash.indexOf('#/registro')>-1){
            console.log(to)
            console.log('Detected old register url, proceeding to redirect to /register')
            if (to.hash.indexOf('?')>-1){
                const params = to.hash.substring(to.hash.indexOf('?')+1)
                console.log('params '+params)
                next('register?'+params)
            } else {
                next('register')
            }
            //const paramsJSON = queryStringToJSON(params);
        } else if (to.hash.indexOf('#/contratos')>-1){
            console.log(to)
            console.log('Detected old powerteam url, proceeding to redirect to powerteam')
            var contractId = to.hash.replace('#/contratos/','')
            contractId = contractId.substring(0,contractId.length)
            window.location.href = "https://powerteam.wearetesters.com/#/contratos/"+contractId
        } else if (to.path.startsWith('/empresas')){
            window.location.href = "https://suite.wearetesters.com"
        } else if (to.path.startsWith('/powerteam')){
            window.location.href = "https://powerteam.wearetesters.com"
        } else {
            next()
        }
    }   
)

type NavigationGuardReturn = void | Error | boolean | RouteLocationRaw

const guard = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    authData: AuthData
): Promise<NavigationGuardReturn> => { 
    if (to.name === 'iframePreview') {
        return
    }
    const needs2fa = await check2FA()

    if(
        authData.isLoggedIn &&
        to.path !== '/' &&
        to.path !== "/2fa" &&
        needs2fa.value
    ){
        return { name: 'validate2fa' }
    }
    else if(authData.isLoggedIn &&
        to.path == "/2fa" &&
        needs2fa.value){
            return
    }
    if (
        to.path === '/entrar' &&
        authData.isLoggedIn &&
        authStore.state.userData.profileCompleted
    ) {
        return { name: 'tests.index' }
    }

    if (
        authData.isLoggedIn
    ) {
        loadCitiServiPixel(authStore.state.userData.email)
    }

    if (
        to.path === '/login' &&
        authData.isLoggedIn &&
        authStore.state.userData.profileCompleted
    ) {
        return { name: 'tests.index' }
    }
    if (
        authData.isLoggedIn &&
        to.path !== '/' &&
        to.path !== '/verify-mobile' &&
        to.path !== '/unique-validation' &&
        ((!authStore.state.userData.mobileValidated &&
            authStore.state.userData.mobileValidationRequired &&
            authStore.state.userData.mobileNum != null) ||
            !authStore.state.userData.profileCompleted)
    ) {
        return { name: 'verify-mobile' }
    }
    if (
        to.path !== '/register' &&
        to.path !== '/forgot-password' &&
        to.path !== '/login' &&
        to.path !== '/verify-email' &&
        to.name !== 'focusPreview' &&
        to.name !== 'focusPublic' &&
        to.name !== 'focusPreviewPublic' &&
        !authData.isLoggedIn 
    ) {
        return { path: '/login' }
    }

    return
}



router.beforeEach(
    async (
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
    ) => {
        if ((to.path == '/entrar' || to.name == 'focustest' || to.name == 'focusPreview') && (typeof to.query.authToken === 'string' && to.query.authToken !== "")) {
            Preferences.set({ key: 'authToken', value: to.query.authToken });
            Preferences.set({ key: 'appToken', value: 'appTokenTemp' });
        }

        await authStore.loadStorageTokens()

        if (authStore.state.isLoggedIn && !authStore.state.deviceRegistered) {
            authStore.setDeviceRegistered()
        }

        const authData: AuthData = {
            isLoggedIn: authStore.state.isLoggedIn,
            appToken: authStore.state.appToken,
            authToken: authStore.state.authToken,
        }

        axios.defaults.headers.common['authToken'] = authData.authToken
        axios.defaults.headers.common['appToken'] = authData.appToken
        const needs2fa = await Preferences.get({key:'needs2FA'})
        if (authData.authToken !== '' && !authStore.state.userDataLoaded && !needs2fa.value) {
            await authStore.getUserDetails()
        }
        return guard(to, from, authData)
    }
)

export default router
