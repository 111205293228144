
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup'
import {
    computed,
    ComputedRef,
    defineComponent,
    inject,
    onBeforeMount,
    ref,
    watch
} from 'vue'
import ProfilePictureChangeBox from '@/components/ProfilePictureChangeBox.vue'
import moment from 'moment'
import { useI18n } from 'vue-i18n'
import i18n from '@/i18n'

import Swal from 'sweetalert2'

export default defineComponent({
    components: { ProfilePictureChangeBox },
    setup() {

        const authStore: any = inject('authState')
        const lovStore: any = inject('lovStore')
        const configurationStore: any = inject('configurationStore')
        const userData: any = computed(() => authStore.state.userData)
        const identityDocTypes: any = ref([])
        const docTypeConfig: any = [
            {
                countryId: 0, // España
                docTypeId: 0, // DNI
                placeholder: 'Ej. 12345678X',
                id: 'identityDocNumInputDNI'
            },
            {
                countryId: 0, // España
                docTypeId: 1, // Pasaporte
                placeholder: 'Ej. A1234567',
                id: 'identityDocNumInputPassport'
            },
            {
                countryId: 0, // España
                docTypeId: 2, // NIE
                placeholder: 'Ej. X1234567X',
                id: 'identityDocNumInputNIE'
            },
            {
                countryId: 1, // Portugal
                docTypeId: 0, // NIC
                placeholder: 'Ej. 12345678 9 AB1',
                id: 'identityDocNumInputNIC'
            },
            {
                countryId: 1, // Portugal
                docTypeId: 1, // Pasaporte
                placeholder: 'Ej. A1234567',
                id: 'identityDocNumInputPassport'
            }
        ];
        const isLoading = ref(false);

        const selectedIdentityDocType = computed(() => {
            if (identityDocTypes.value && identityDocTypes.value.length > 0) {
                const data = identityDocTypes.value.find((doc:any) => doc.id === identityDocType.value);
                return data;
            }
                return null;
        }); 
        const identityDocInputAttributes = computed(() => {
            const countryId = userData.value.residenceCountry.elementId;
            const docTypeId = identityDocType.value;

            // Buscar el objeto que coincide con el país y el tipo de documento
            const config = docTypeConfig.find((item: any) => item.countryId === countryId && item.docTypeId === docTypeId);

            // Si se encuentra una configuración, la retornamos, de lo contrario devolvemos valores por defecto
            return config || {
                placeholder: 'Introduce el número de documento',
                id: 'identityDocNumInputDefault'
            };
        });


        const { validateField } = useForm();

        const { errorMessage: errorAlias, value: alias } = useField<string>(
            'alias',
            yup.string().required(() => i18n.global.t('FIELD_REQUIRED'))
        )
        const { errorMessage: errorName, value: name } = useField<string>(
            'name',
            yup.string().required(() => i18n.global.t('FIELD_REQUIRED'))
        )
        const { errorMessage: errorSurname1, value: surname1 } =
            useField<string>(
                'surname1',
                yup.string().required(() => i18n.global.t('FIELD_REQUIRED'))
            )
        const { errorMessage: errorSurname2, value: surname2 } =
            useField<string>(
                'surname2',
                yup.string().required(() => i18n.global.t('FIELD_REQUIRED'))
            )
        const { errorMessage: errorGender, value: gender } = useField<any>(
            'gender',
            yup.mixed().oneOf(['M', 'W']).required(() => i18n.global.t('FIELD_REQUIRED'))
        )
        const { errorMessage: errorDate, value: date } = useField<any>(
            'date',
            yup
                .date()
                .nullable()
                .test('noEmpty', () => i18n.global.t('FIELD_REQUIRED'), (value: any) => {
                    if (value === null) {
                        return false
                    }
                    return true
                })
                .test('DOB', () => i18n.global.t('FORM_ERROR_MIN_ADULT'), (value: any) => {
                    if (value === null) {
                        return true
                    }
                    return moment().diff(value, 'years') >= 16
                })
                .test('DOB', () => i18n.global.t('FORM_ERROR_MAX_ADULT'), (value: any) => {
                    if (value === null) {
                        return true
                    }
                    return moment().diff(value, 'years') <= 100
                })
                .transform(function (value: any, originalValue: any) {
                    const parsedDate =
                        originalValue === '' ? null : new Date(originalValue)
                    return parsedDate
                })
        )

        const { errorMessage: errorIdentityDocType, value: identityDocType } =
            useField<number>(
                'identityDocType',
                yup.number()
            )

            const { errorMessage: errorIdentityDocNum, value: identityDocNum } =
    useField<any>(
        'identityDocNum',
        yup
            .string()
            .nullable()
            .test('isValidIdentityDocNum', function (value) {
                if (userData.value.residenceCountry.elementId === 0) {
                    // Validación para DNI o NIE en caso de que el país de residencia sea España (elementId = 0)
                    if (identityDocType.value === 0) {
                        return /^[0-9]{8}[A-Za-z]$/.test(value ?? '') || this.createError({
                            message: i18n.global.t('INVALID_DNI'),
                        });
                    } 
                     if (identityDocType.value === 2) {
                        return /^[XYZxyz][0-9]{7}[A-Za-z]$/.test(value ?? '') || this.createError({
                            message: i18n.global.t('INVALID_NIE'),
                        });
                    }
                } else if (userData.value.residenceCountry.elementId === 1) {
                    // Validación para NIC en caso de que el país de residencia sea Portugal (elementId = 1)
                    if (identityDocType.value === 0) {
                        return /^[0-9]{8}\s[0-9]\s[A-Za-z]{2}[0-9]$/.test(value ?? '') || this.createError({
                            message: i18n.global.t('INVALID_DNI'),
                        });
                    }
                    if (identityDocType.value === 2) {
                        return /^[XYZxyz][0-9]{7}[A-Za-z]$/.test(value ?? '') || this.createError({
                            message: i18n.global.t('INVALID_NIE'),
                        });
                    }
                }
                return true;
            })
            .required(() => i18n.global.t('FIELD_REQUIRED'))
    );
            
        const { errorMessage: errorProvince, value: province } =
            useField<number>(
                'province',
                yup.number().required(() => i18n.global.t('FIELD_REQUIRED'))
            )
        let matchesValue, minValue, maxValue;
        switch (authStore.state.userData.residenceCountry.elementId) {
            case 0:
                matchesValue = /^[0-9]+$/;
                minValue = 5;
                maxValue = 5;
                break;
            case 1: // Portugal
                matchesValue = /^[0-9]{4}-[0-9]{3}$/; // Código postal portugués
                minValue = 8;
                maxValue = 8;
                break;
            default:
                matchesValue = /^\d{4}-\d{3}$/;
                minValue = 8;
                maxValue = 8;
                break;
        }
        const { errorMessage: errorPostalCode, value: postalCode } =
            useField<string>(
                'postalCode',
                yup
                    .string()
                    .required(() => i18n.global.t('FIELD_REQUIRED'))
                    .matches(matchesValue, () => i18n.global.t('WRONG_POSTALCODE_FORMAT'))
                    .min(minValue, () => i18n.global.t('WRONG_POSTALCODE_FORMAT'))
                    .max(maxValue, () => i18n.global.t('WRONG_POSTALCODE_FORMAT'))
            )
        const { errorMessage: errorAddress, value: address } = useField<any>(
            'address',
            yup.string().required(() => i18n.global.t('FIELD_REQUIRED'))
        )
        const { errorMessage: errorLocality, value: locality } =
        useField<any>('locality', yup.string().required(() => i18n.global.t('FIELD_REQUIRED')))


        const validateIdentityDocNum = () => {
            validateField('identityDocNum');
        };

        // Watch for changes in identityDocType and re-validate identityDocNum
        watch(identityDocType, () => {
            validateIdentityDocNum();
        });

        
        const formAddressValid: ComputedRef<boolean> = computed(
    (): boolean =>
        Number(province.value) >= 0 &&
        !!postalCode.value &&
        !!locality.value &&
        !errorProvince.value &&
        !errorPostalCode.value &&
        !errorAddress.value &&
        !errorLocality.value 
);

const formPersonalValid: ComputedRef<boolean> = computed(
    (): boolean =>
        !!alias.value &&
        !!name.value &&
        !!surname1.value &&
        !!surname2.value &&
        !!gender.value &&
        !!date.value &&
        !errorAlias.value &&
        
        !errorName.value &&
        !errorSurname1.value &&
        !errorSurname2.value &&
        !errorGender.value &&
        identityDocType.value >= 0 &&
        !!identityDocNum.value &&
        !errorIdentityDocType.value &&
        !errorIdentityDocNum.value &&
        !errorDate.value
);

onBeforeMount(async () => {
    identityDocType.value = 0;
    await lovStore.actions.getProvinces(null, { country: authStore.state.userData.residenceCountry.secondaryValue });
    identityDocTypes.value = await lovStore.actions.getIdentityDocTypes(null, { country: authStore.state.userData.residenceCountry.secondaryValue });
    if(authStore.state.userData.residenceCountry.elementId === 0) identityDocTypes.value = identityDocTypes.value.filter((doc: any) => doc.id !== 1);
    if(authStore.state.userData.residenceCountry.elementId === 1) identityDocTypes.value = identityDocTypes.value.filter((doc: any) => doc.id === 0);

    alias.value = authStore.state.userData.nickname;
    
    
    name.value = authStore.state.userData.name;
    surname1.value = authStore.state.userData.surname1;
    surname2.value = authStore.state.userData.surname2;
    gender.value = authStore.state.userData.gender;
    date.value = moment(authStore.state.userData.bornDate).format('YYYY-MM-DD');
    province.value = authStore.state.userData.province?.id;
    postalCode.value = authStore.state.userData.postalCode ?? '';
    address.value = authStore.state.userData.address ?? '';
    locality.value = authStore.state.userData.locality ?? '';
    identityDocNum.value = authStore.state.userData.identityDocNum; 
    if (authStore.state.userData.identityDocType) {
        identityDocType.value = lovStore.state.lov.identityDocTypes.find((i: { id: any }) => i.id == authStore.state.userData.identityDocType.id).id;
    }
});


        const updateImagePath = async (value: any) => {
            isLoading.value = true;
            const data = value
            const success = await configurationStore.updateProfileImage(data)
            if (!success) {
                isLoading.value = false;
                Swal.fire({
                    icon: 'error',
                    title: i18n.global.t('Error_Try_Again'),
                })
                
            }
            await authStore.getUserDetails()
            isLoading.value = false;
        }

        const updateGender = (value: string) => {
            gender.value = value
        }
        const updatePersonalData = async () => {
        
    if (!formPersonalValid.value) {
        return;
    }
    const data = {
        nickname: alias.value,
        name: name.value,
        surname1: surname1.value,
        surname2: surname2.value,
        gender: gender.value,
        bornDate: date.value.replaceAll('-', '/'),
        identityDocType: {
            elementId: identityDocType.value
        },
        identityDocNum: identityDocNum.value,
        residenceCountry: authStore.state.userData.residenceCountry
    };

    try {
        isLoading.value = true;
        const success = await configurationStore.updatePersonalData(data);
        if (!success) {
            isLoading.value = false;
            return Swal.fire({
                icon: 'error',
                title: setErrorMessage(configurationStore.state.details.personalDataForm.errorMessage)
            });
        }
         await authStore.getUserDetails();
        isLoading.value = false;
        Swal.fire({
            icon: 'success',
            title: 'Hecho ;)',
            text: i18n.global.t('tester_profile_updated'),
        });
    } catch (error) {
        console.error('Error updating personal data:', error);
        isLoading.value = false;
        Swal.fire({
            icon: 'error',
            title: i18n.global.t('ERROR_TRY_AGAIN'),
        });
    }
};

const updateAddressData = async () => {
    if (!formAddressValid.value) {
        return;
    }
    const data = {
        address: address.value,
        postalCode: postalCode.value,
        locality: locality.value,
        province: { elementId: province.value },
        // identityDocType: { elementId: identityDocType.value },
        // identityDocNum: identityDocNum.value,
        residenceCountry: authStore.state.userData.residenceCountry
    };

    try {
        isLoading.value = true;
        const success = await configurationStore.updateAddressData(data);
        if (!success) {
            isLoading.value = false;
            return Swal.fire({
                icon: 'error',
                title: setErrorMessage(configurationStore.state.details.addressDataForm.errorMessage)
            });
        }
        await authStore.getUserDetails();
        isLoading.value = false;
        Swal.fire({
            icon: 'success',
            title: 'Hecho ;)',
            text: i18n.global.t('tester_profile_updated'),
        });
    } catch (error) {
        console.error('Error updating address data:', error);
        isLoading.value = false;

        Swal.fire({
            icon: 'error',
            title: i18n.global.t('ERROR_TRY_AGAIN'),
        });
    }
};
        const setErrorMessage = (errorCode: any) => {
            switch (errorCode) {
                case 'dni_is_already_in_use':
                    return i18n.global.t('tester_personal_data_dni_error');
                case 'nickname_is_already_in_use':
                    return i18n.global.t('tester_personal_data_nickname_error');
                case 'email_is_already_in_use':
                    return i18n.global.t('tester_personal_data_email_error');
                case 'cif_is_not_valid':
                    return i18n.global.t('tester_personal_data_nif_error');
                case 'paypal_is_not_valid':
                    return i18n.global.t('tester_personal_data_paypal_error');
                case 'default_error_message':
                default:
                    return i18n.global.t('tester_personal_data_default_error');
            }
        }
        return {
            alias,
            errorAlias,
            name,
            errorName,
            surname1,
            errorSurname1,
            surname2,
            errorSurname2,
            date,
            errorDate,
            identityDocNum,
            identityDocType,
            errorIdentityDocNum,
            errorIdentityDocType,
            province,
            errorProvince,
            gender,
            errorGender,
            postalCode,
            errorPostalCode,
            address,
            errorAddress,
            locality,
            errorLocality,
            updateImagePath,
            formAddressValid,
            formPersonalValid,
            lovStore,
            updateGender,
            userData,
            updatePersonalData,
            updateAddressData,
            setErrorMessage,
            validateIdentityDocNum,
            identityDocTypes,
            selectedIdentityDocType,
            docTypeConfig,
            identityDocInputAttributes,
            isLoading
        }
    },
})
