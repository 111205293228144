<template lang="html">
  <div class="MultiSelection" :class="{'IE11':isIE11()}">
    <span class="MultiSelection__Required" v-if="question.question.multiselectionMax < question.question.values.length">{{$parent.translationMethod('max_answer2')}} {{question.question.multiselectionMax}}</span>
    <span class="MultiSelection__Required" v-if="question.question.multiselectionMin">{{$parent.translationMethod('min_answer')}} {{question.question.multiselectionMin}}</span>
    <div class="MultiSelection__Container" :class="{'column':question.question.values.length < 8}">
      <div class="MultiSelection__Container--Wrapper MultiSelection--Group" :class="{'selected':g.selected}" v-for="(g,i) in question.question.groups" v-show="getValues(null,g.values).length > 0">
        <div class="MultiSelection--GroupOption" v-on:click="openGroup(g)">
          <i v-show="g.selected">-</i>
          <i v-show="!g.selected">+</i>
          {{g.groupName}} <span v-show="getSelectionsNumByGroup(g) > 0">({{getSelectionsNumByGroup(g)}})</span>
        </div>
        <div class="MultiSelection--GroupValues" :class="{'opened':g.selected}">
          <div class="MultiSelection__Container--Wrapper" :style="{'order':v.order}" v-for="(v,i) in getValues(null, g.values)">
            <div class="MultiSelection__Option">
              <input type="checkbox" :id="'answer_'+question.question.order + '_' + v.id" :name="'answer_'+question.question.order+'_'+v.id" :value="v.id" v-model="v.checked" v-on:click="addAnswer(v)" :disabled="disableValues(v)">
              <label :for="'answer_' + question.question.order + '_' + v.id">
                <span :class="{ 'checked': v.checked }"></span>
                <i class="fa fa-check" v-show="v.checked"></i>
                <p style="margin: 0;" v-html="v.value"></p>
            </label>
            </div>
            <textarea
              class="MultiSelection__Addtxt"
              :placeholder="v.addTxtPlaceholder!=undefined && v.addTxtPlaceholder!=null && v.addTxtPlaceholder != '' ? v.addTxtPlaceholder : $parent.translationMethod('put_more_info')"
              v-if="v.addtxt && answers.includes(v.id)"
              v-model="addedTexts[v.id]"
              v-on:keyup="addText($event,v)"></textarea>
          </div>
        </div>
      </div>
      <div class="MultiSelection__Container--Wrapper" :style="{'order':v.order}" v-for="(v,i) in getValues('ALL',null)">
        <div class="MultiSelection__Option" >
          <input type="checkbox" :id="'answer_'+question.question.order + '_' + v.id" :name="'answer_'+question.question.order+'_'+v.id" :value="v.id" v-model="v.checked" v-on:click="addAnswer(v)" :disabled="disableValues(v)">
          <label :for="'answer_' + question.question.order + '_' + v.id">
            <span :class="{ 'checked': v.checked }"></span>
            <i class="fa fa-check" v-show="v.checked"></i>
            <p style="margin: 0;" v-html="v.value"></p>
        </label>
        </div>
        <textarea
          class="MultiSelection__Addtxt"
          :placeholder="v.addTxtPlaceholder!=undefined && v.addTxtPlaceholder!=null && v.addTxtPlaceholder != '' ? v.addTxtPlaceholder : $parent.translationMethod('put_more_info')"
          v-if="v.addtxt && answers.includes(v.id)"
          v-model="addedTexts[v.id]"
          v-on:keyup="addText($event,v)"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiSelection',

  props: {
    question:{
      type: Object,
      default: null
    },
  },

  data() {
    return {
      answers:[],
      beforeAnswers:[],
      addedTexts:{},
        values: [],
    }
  },

  methods: {
    checkAnswerValid() {
    let answerValid = true;

    // Check if the number of selections meets the minimum requirement
    if (this.question.question.multiselectionMin) {
      if (this.answers.length < this.question.question.multiselectionMin) {
        answerValid = false;
      }
    } else {
      if (this.answers.length === 0) {
        answerValid = false;
      }
    }

    // Check if all required added texts are filled
    for (let v of this.values) {
      if (this.answers.includes(v.id) && v.addtxt) {
        let text = this.addedTexts[v.id];
        if (!text || text.trim().length === 0) {
          answerValid = false;
          break; 
        }
      }
    }

    return answerValid;
  },
    getSelectionsNumByGroup(group){
      let count = 0;
      for(let vId of group.values){
        if(this.answers.includes(vId)) count ++
      }
      return count;
    },
    openGroup(group){
      group.selected = !group.selected
      this.values = this.values.map(function(v) {
        if(group.values.includes(v.id)) v.checked = v.checked != undefined ? v.checked : false
        return v
      })
    },
    isAddedToGroup(valueId){
      let isAdded = false
      if(this.question.question.groups == undefined || this.question.question.groups == null || this.question.question.groups.length == 0) return isAdded
      for(let g of this.question.question.groups){
        if(g.values.includes(valueId)) isAdded = true
      }
      return isAdded
    },
    getValues(type, groupValues){
      let values = []
      if(type == "ALL"){
        for(let v of this.values){
          if(!this.isAddedToGroup(v.id)) values.push(v)
        }
      }
      else{
        for(let v of this.values){
          if(groupValues.includes(v.id)) values.push(v)
        }
      }
      return values
    },
      addAnswer(value) {
          if (!this.answers.includes(value.id) && value.exclusive) {
              this.values.forEach((v) => {
                  v.checked = false; 
              });

              value.checked = true;
              this.answers = [value.id];

            let answerValid = this.checkAnswerValid();

              this.$emit('send-answers', {
                  answer: { multipleVals: this.answers },
                  question: this.question,
                answerValid: answerValid,
              });
              return;
          }

          if (this.answers.includes(value.id)) {
              value.checked = false;
              this.answers.splice(this.answers.indexOf(value.id), 1);
          } else {
              value.checked = true;
              this.answers.push(value.id);
          }

        let answerValid = this.checkAnswerValid();

        this.$emit('send-answers', {
          answer: { multipleVals: this.answers, addTexts: this.addedTexts },
          question: this.question,
          answerValid: answerValid,
        });
      },
    disableValues(value){
      for(let v of this.values){
        if(this.answers.includes(v.id) && v.exclusive && value.id != v.id){
          value.checked = false
          return true
        }
      }
      if (this.answers.length == this.question.question.multiselectionMax && !this.answers.includes(value.id)) return true
      return false
    },
    addText(e,value){
      this.addedTexts[value.id] = e != undefined ? e.target.value : ""
      let answerValid = this.checkAnswerValid();

      this.$emit('send-answers', {
        answer: { multipleVals: this.answers, addTexts: this.addedTexts },
        question: this.question,
        answerValid: answerValid,
      });
    },
    saveText(){
      let answerValid = true
      for(let v of this.values){
        if(this.answers.includes(v.id) && v.addtxt && (this.addedTexts[v.id] == undefined || this.addedTexts[v.id].length < 1)) answerValid = false
      }
      this.$emit('send-answers', {answer:{multipleVals:this.answers,addTexts:this.addedTexts},question:this.question, answerValid:answerValid})
    },
    isIE11(){
      return !!window.MSInputMethodContext && !!document.documentMode;
    },
  },
  async mounted(){
      this.values = this.question.question.values.map((v) => ({
          ...v,
          checked: false, 
      }));

    if (this.question.answer) {
      this.answers = this.question.answer.multipleVals
      for (let v of this.values) {
        if (this.answers.includes(v.id)) v.checked = true
      }
      this.addedTexts = this.question.answer.addTexts
      if(this.addedTexts != null && this.addedTexts != undefined && this.addedTexts.length > 0) this.$emit('send-answers', {answer:{multipleVals:this.answers,addTexts:this.addedTexts},question:this.question, answerValid:true})
      else this.$emit('send-answers', {answer:{multipleVals:this.answers},question:this.question, answerValid:true})
    }
  }
}
</script>

<style lang="scss" scoped>
.IE11{
  .MultiSelection{
    &__Option{
      label{
        i{
          background: none!important;
        }
      }
    }
  }
}
.MultiSelection{
  display: flex;
  flex-direction: column;
  &__Required{
    text-align: left;
  }
  &__Container{
    display:flex;
    flex-wrap: wrap;
    margin-top: 10px;
    &.column{
      flex-direction: column;
      .MultiSelection__Container--Wrapper{
        order:0!important;
      }
    }
    &--Wrapper{
      position:relative;
      cursor: pointer;
      width: 50%;
    }
  }
  &__Option{
    width: 100%;
    display: flex;
    input {
    	display: none;
      &:checked + label{
        color: var(--main-color);
        span{
          display:none;
        }
        i{
          display:block;
        }
      }
      &:disabled + label{
        span{
          background: lightgray;
          cursor: not-allowed;
        }
      }
    }
    label{
      display: flex;
      font-weight: 500;
        align-items: baseline;
      cursor: pointer;
      i{
        font-size: 24px;
        height: 24px;
        margin-right: 8px;
        background: linear-gradient(to right,var(--main-color) , var(--secondary-color));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      span{
        display: flex;
        width: 20px;
        min-width: 20px;
        height: 20px;
        margin: 1px 10px 3px 3px;
        padding:4px;
        vertical-align: middle;
        border: 1px solid #aeaeae;
        border-radius: 5px;

        &.checked {
                border-color: var(--main-color);
                background: var(--main-color);
            }
      }
          &.checked {
              border-color: var(--main-color);
              background: var(--main-color);
          }
    }
  }
   input:checked + label i {
    display: block;
  }

  &__Addtxt{
    outline: none;
    width: 100%;
    margin-bottom:10px;
    border: none;
    border-bottom: 1px solid #aeaeae;
    animation: fadeIn 0.2s;
    resize:none;
  }
  &--Group{
    &.selected{
      margin-bottom: 5px;
      padding-top: 5px;
    }
  }
  &--GroupOption{
    width: 100%;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    i{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      min-width: 20px;
      height: 20px;
      margin: 1px 10px 3px 3px;
      padding:4px;
      vertical-align: middle;
      border: 1px solid #aeaeae;
      border-radius: 5px;
      color: var(--button-color);
      font-size: 22px;
      font-style: unset;
    }
    span{
      font-size: 0.9vw;
      margin-left: 3px;
    }
  }
  &--GroupValues{
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
    &.opened{
      padding: 5px 5px 5px 20px;
      max-height: 2000px;
      transition: max-height 0.25s ease-in;
    }
  }
  @media only screen and ( max-width: 769px ) {
    &__Container{
      flex-direction: column;
      &--Wrapper{
        width: 100%;
        margin-bottom: 5px;
        order:0!important;
      }
    }
    &__Option{
      span{
        background: white;
      }
    }
    &--GroupOption{
      span{
        font-size: 15px;
      }
      i{
        background: white;
      }
    }
    // &__Addtxt{
    //   background: transparent;
    //   &::placeholder{
    //     color: white;
    //   }
    // }
  }
}
@keyframes fadeIn{
  from{opacity:0;}
  to{opacity:1;}
}
</style>
