
import { defineComponent } from 'vue';
import store from "@/store"
import WatAcademyMedal from '@/components/WatAcademyMedal.vue'
import authStore from "@/store/auth.store";
export default defineComponent({
    name: 'WatAcademyNew',
    components:{
        WatAcademyMedal
    },
    data(){
        return{
            showInfo: true,
            medals:[
                {
                    icon:'medal1.svg',
                    color:'#B1874E',
                    title:'Novato del Conocimiento',
                    text:"¡Has iniciado tu misión! Como un joven padawan, estás listo para conquistar el vasto universo del saber.",
                    tagId:108,
                    activity: 5394
                },
                {
                    icon:'medal2.svg',
                    color:'#B2C3D3',
                    title:'Aprendiz analítico',
                    text:"Tu mente funciona como un ordenador de alta gama. Has demostrado habilidades de análisis dignas de un maestro Jedi.",
                    tagId:109,
                    activity: 5395
                },
                {
                    icon:'medal3.svg',
                    color:'#E39532',
                    title:'Pionero del Conocimiento',
                    text:"Eres el Indiana Jones del aprendizaje, explorando territorios inexplorados y descubriendo tesoros de información.",
                    tagId:110,
                    activity: 5396
                },
                {
                    icon:'medal4.svg',
                    color:'#C6CDE4',
                    title:'Explorador de Ideas',
                    text:"Como un intrépido explorador del multiverso, generas y navegas por ideas innovadoras que desafían la realidad.",
                    tagId:111,
                    activity: 5397
                },
                {
                    icon:'medal5.svg',
                    color:'#FAE066',
                    title:'Maestro del Descubrimiento',
                    text:"Has alcanzado el nivel de sabiduría de un mago en Hogwarts, identificando conocimientos profundos con maestría",
                    tagId:112,
                    activity: 5398
                },
                {
                    icon:'medal6.svg',
                    color:'#F977F0',
                    title:'Guardián de la Experiencia',
                    text:"Proteges y compartes tus valiosas experiencias como un sabio druida que preserva el conocimiento ancestral.",
                    tagId:113,
                    activity: 5399
                },
                {
                    icon:'medal7.svg',
                    color:'#5AC2FC',
                    title:'Arquitecto de Comunidades',
                    text:"Construyes y fortaleces redes como un maestro constructor de la Torre de Babel, conectando a todos los héroes de la app.",
                    tagId:114,
                    activity: 5400
                },
                {
                    icon:'medal8.svg',
                    color:'linear-gradient(270deg, #7962EC 0%, #F963B2 51.5%, #FFCFA2 100%)',
                    title:'Oráculo Digital',
                    text:"Eres el Gandalf de la plataforma, ofreciendo respuestas sabias y soluciones épicas a cualquier desafío digital.",
                    tagId:115,
                    activity: 5401
                },
                {
                    icon:'medal9.svg',
                    color:'linear-gradient(90deg, #FF1564 0%, #FD7328 100%)',
                    title:'El WATelegido',
                    text:"La medalla legendaria que solo los héroes más valientes y excepcionales pueden alcanzar. ¡Eres el elegido de la saga!",
                    tagId:116,
                    activity: 5402
                }
            ]
        }
    },
    methods:{
        fetchUserMedals(){
            for(let m of this.medals){
                const medal: any = m;
                const tags: any = authStore.state.userData.testerTags;
                if (tags.includes(m.tagId)) {
                    medal.completed = true;
                }
            }
        },
        login(){
            authStore.loginWatAcademy().then((data) => {
                window.open("https://watlab.es/c/watacademypage/login?testerAuthToken="+data.authToken, "_blank");
            })
            .catch((error) => {
                alert("Error")
                console.log(error)
            });
        },
        loginActivity(activityId: any){
            authStore.loginWatAcademy().then((data) => {
                window.open("https://watlab.es/c/watacademypage/activities/" +activityId+ "/info?testerAuthToken="+data.authToken, "_blank");
            })
            .catch((error) => {
                alert("Error")
                console.log(error)
            });
        }
    },
    mounted(){
        this.fetchUserMedals()
    }
})
