
import * as yup from 'yup'
import { useField } from 'vee-validate'
import {
    defineComponent,
    inject,
    ComputedRef,
    computed,
    ref,
    onMounted,
} from 'vue'
import { useRouter } from 'vue-router'
import {
    IonContent,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonPage,
    IonInput,
    IonItem,
    IonLabel,
    IonButton,
    toastController,
} from '@ionic/vue'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import i18n from '@/i18n'
import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'
import Swal from 'sweetalert2'

const componentName = 'ForgotPassword'
const components = {
    IonContent,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonPage,
    IonInput,
    IonItem,
    IonLabel,
    IonButton,
}

const setup = () => {
    const router = useRouter()
    const authStore: any = inject('authState')
    const showError = computed(() => authStore.state.resetPassword.showError)
    const errorMessage = computed(
        () => authStore.state.resetPassword.errorMessage
    )
    const showNewPassword: any = ref(false)
    const route: any = useRoute()
    const userId: any = ref('')
    const codeAction: any = ref('')
    const confirmPassword: any = ref('')


    const { errorMessage: errorMail, value: email } = useField<string>(
        'email',
        yup
            .string()
            .email(i18n.global.t('WRONG_EMAIL_FORMAT'))
            .required(i18n.global.t('FIELD_REQUIRED'))
    )
    const { errorMessage: passwordError, value: password } = useField<string>(
        'password',
        yup
            .string()
            .min(6, i18n.global.t('WRONG_PASSWORD_FORMAT'))
            .required(i18n.global.t('FIELD_REQUIRED'))
    )
    const formValid: ComputedRef<boolean> = computed(
        (): boolean => !!email.value && !errorMail.value
    )
    const passwordFormValid: ComputedRef<boolean> = computed(
        (): boolean => !!password.value && !passwordError.value
    )

    const _showToast = async () => {
        const toast = await toastController.create({
            color: 'primary',
            duration: 2000,
            message:i18n.global.t('tester_email_recovery_password'),
            position: 'top',
        })

        await toast.present()
    }

    const resetPassword = async () => {
        if (!formValid.value) return

        const success = await authStore.resetPassword(email.value)
        if (!success) {
            return
        }

        _showToast()
    }

    const handlePause = async () => {
        if (Capacitor.isNativePlatform()) {
            await authStore.logUserOut()
            goTo('login')
            App.removeAllListeners();
            App.exitApp();
        }
    }

    const goTo = (url: any) => {
        router.push({ name: url })
    }

    const checkResetCode = async () => {
        const chechkResetCodeData = {
            userId: userId.value,
            data: {
                codeAction: codeAction.value,
            },
        }
        await authStore.checkResetCode(chechkResetCodeData)
    }

    const setNewPassword = async () => {
        if (!passwordFormValid.value) return
        if (password.value !== confirmPassword.value) {
            authStore.state.resetPassword.showError = true
            authStore.state.resetPassword.errorMessage = i18n.global.t('tester_email_recovery_password')
            return
        }
        const resetNewPasswordData = {
            userId: userId.value,
            data: {
                password: password.value,
                codeAction: codeAction.value,
            },
        }
        const success = await authStore.resetNewPassword(resetNewPasswordData)
        if (success) {
            Swal.fire({
                title: i18n.global.t('PASSWORD_UPDATED'),
                text: i18n.global.t('USE_NEW_PASSWORD'),
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Vale',
                showCancelButton: false,
                allowOutsideClick: false
            }).then(async(result) =>{
                router.push({ name: 'login' })
            })
        }
    }

    onMounted(async () => {
        if (
            route.query.userId != undefined &&
            route.query.codeAction != undefined
        ) {
            userId.value = route.query.userId
            codeAction.value = route.query.codeAction
            showNewPassword.value = true
            await checkResetCode()
        }
        if (Capacitor.isNativePlatform()) {
            App.addListener('pause', handlePause);
        }
    })
    onBeforeRouteLeave(() => {
        authStore.state.resetPassword.showError = false
        authStore.state.resetPassword.errorMessage = ''
    })

    return {
        email,
        errorMail,
        showError,
        errorMessage,
        formValid,
        passwordFormValid,
        resetPassword,
        showNewPassword,
        setNewPassword,
        password,
        passwordError,
        confirmPassword,
    }
}

export default defineComponent({ name: componentName, components, setup })
