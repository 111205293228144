<template>
    <div id="silentbox-overlay" v-if="isVisible">
        <div id="silentbox-overlay__background" @click.stop="closeSilentBoxOverlay"></div>

        <div id="silentbox-overlay__content">
            <div id="silentbox-overlay__embed">
                <div id="silentbox-overlay__container">
                    <video id="video" style="width:100%; height:100%" v-if="video" allowfullscreen autoplay :controls="isCommunity" controlsList="nodownload noremoteplayback noplaybackrate" disablepictureinpicture>
                      <source :src="getEmbedUrl" type='video/mp4;'>
                      <source :src="getEmbedUrl" type="video/ogg">
                    </video>
                    <iframe :src="getEmbedUrl" width="100%" height="100%" v-if="videoYouTube"></iframe>
                    <img draggable="false"
                        @dblclick="toggleZoom" 
                        @mousedown="startDrag" 
                        @mousemove="onDrag" 
                        @mouseup="endDrag" 
                        @mouseleave="endDrag"
                        @touchstart="startDrag" 
                        @touchmove="onDrag" 
                        @touchend="endDrag" 
                        @wheel="onScrollZoom"
                        :style="{ transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)` }" 
                        :src="getEmbedUrl" 
                        v-if="!video && !videoYouTube"
                        />
                    
                    <!-- Input range for zoom control -->
                    <div class="zoom" v-if="!video && !videoYouTube">
                        <div class="zoom__container">
                            <button class="zoom__button" @click="decreaseZoom">&minus;</button>
                            <span class="zoom__label">{{ scale.toFixed(1) }}x</span>
                            <button class="zoom__button" @click="increaseZoom">&plus;</button>

                        </div>
                        <button class="zoom__button" @click="restartZoom"> <i class="fas fa-expand"></i> </button>
                    </div>
                </div>
                <p id="silentbox-overlay__description" v-if="this.$parent.description">{{ this.$parent.description }}</p>
            </div>
        </div>

        <div id="silentbox-overlay__close-button" role="button" tabindex="3" @click.stop="closeSilentBoxOverlay" @keyup.enter="closeSilentBoxOverlay">
            <div class="icon"></div>
        </div>

        <div id="silentbox-overlay__arrow-buttons" v-if="this.$parent.items.total > 1">
            <div class="arrow arrow-previous" role="button" tabindex="2" @click="moveToPreviousItem" @keyup.enter="moveToPreviousItem"></div>
            <div class="arrow arrow-next" role="button" tabindex="1" @click="moveToNextItem" @keyup.enter="moveToNextItem"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SilentboxOverlay',
        data() {
            return {
                video: false,
                videoYouTube: false,
                isCommunity: false,
                scale: 1.0,
                isDragging: false,
                startX: 0,
                startY: 0,
                translateX: 0,
                translateY: 0,
            }
        },
        computed: {
            getEmbedUrl() {
                return this.handleUrl(this.$parent.embedUrl);
            },
            isVisible() {
                if (this.$parent.overlayVisibility !== undefined && this.$parent.overlayVisibility !== false) {
                    return true;
                }

                return false;
            },
            
        },
        methods: {
            decreaseZoom() {
                const zoomSpeed = 0.1;
                this.scale = Math.max(this.scale - zoomSpeed, 0.1); // Limita el zoom mínimo a 0.1x
                this.scale = Math.round(this.scale * 100) / 100; // Redondea el valor de scale a dos decimales
            },
            increaseZoom() {
                const zoomSpeed = 0.1;
                this.scale = Math.min(this.scale + zoomSpeed, 3); // Limita el zoom máximo a 3x
                this.scale = Math.round(this.scale * 100) / 100; // Redondea el valor de scale a dos decimales
            },
            restartZoom(){
                this.scale = 1.0;
            },
            toggleZoom() {
                this.scale = this.scale === 1.0 ? 2.0 : 1.0; // Doble clic alterna entre zoom normal y 2x
            },
            startDrag(event) {
                event.preventDefault();
                this.isDragging = true;
                // Si es un evento táctil, usa `touches` para obtener las coordenadas
                const clientX = event.type === 'touchstart' ? event.touches[0].clientX : event.clientX;
                const clientY = event.type === 'touchstart' ? event.touches[0].clientY : event.clientY;
                this.startX = clientX - this.translateX;
                this.startY = clientY - this.translateY;
            },
            onDrag(event) {
                if (this.isDragging) {
                    event.preventDefault(); // Prevenir el desplazamiento de la página
                    const clientX = event.type === 'touchmove' ? event.touches[0].clientX : event.clientX;
                    const clientY = event.type === 'touchmove' ? event.touches[0].clientY : event.clientY;
                    this.translateX = clientX - this.startX;
                    this.translateY = clientY - this.startY;
                }
            },
            endDrag() {
                if (this.isDragging) {
                this.isDragging = false;
                }
            },
            onScrollZoom(event) {
                event.preventDefault(); // Prevenir el comportamiento de scroll de página
                const zoomSpeed = 0.1; // Ajusta la velocidad de zoom

                if (event.deltaY < 0) {
                    // Zoom in
                    this.scale = Math.min(this.scale + zoomSpeed, 3); // Limita el zoom máximo
                } else {
                    // Zoom out
                    this.scale = Math.max(this.scale - zoomSpeed, 0.1); // Limita el zoom mínimo a 0.1x
                }

                // Redondea el valor de scale a dos decimales
                this.scale = Math.round(this.scale * 100) / 100;
            },
            moveToNextItem() {
                this.$parent.nextItem();
            },
            moveToPreviousItem()
            {
                this.$parent.prevItem();
            },
            closeSilentBoxOverlay() {
                this.$parent.closeSilentBoxOverlay();
            },
            handleUrl(url) {
              if (url.match(/\.(jpeg|jpg|JPG|gif|png|PNG)$/) != null) {
                this.video = false
                this.videoYouTube = false
              }
              else if(url.includes('youtube.com')){
                this.video = false;
                this.videoYouTube = true

                let videoIdPosition  = url.indexOf('v=') + 2;
                let videoId = url.substring(videoIdPosition);

                let videoUrl = 'https://www.youtube.com/embed/' + videoId;

                if (this.$parent.autoplay) {
                    videoUrl += '?autoplay=1';
                }

                return videoUrl;
              }
              else{
                this.video = true
                this.videoYouTube = false
              }
              return url
                // if (url.includes('youtube.com')) {
                //     this.video = true;
                //
                //     let videoIdPosition  = url.indexOf('v=') + 2;
                //     let videoId = url.substring(videoIdPosition);
                //
                //     let videoUrl = 'https://www.youtube.com/embed/' + videoId;
                //
                //     if (this.$parent.autoplay) {
                //         videoUrl += '?autoplay=1';
                //     }
                //
                //     return videoUrl;
                // } else {
                //     this.video = false;
                //
                //     return url;
                // }
            }
        },
        async beforeUpdate() {
            let body = document.body;
            let pageLocation = document.location.href;

            // add class only if overlay should be visible
            if (this.isVisible && ! body.classList.contains('silentbox-is-opened')) {
                if (pageLocation.includes('activities')) {
                    this.isCommunity = true;
                } else {
                    this.isCommunity = false;
                }
                return body.classList.add('silentbox-is-opened');
            }

            // remove class only if overlay should be hidden
            if (! this.isVisible && body.classList.contains('silentbox-is-opened')) {
                return body.classList.remove('silentbox-is-opened')
            }
        },
        async updated(){          
          if (this.isVisible) {
            $('#silentbox-overlay').appendTo("body")
            if(this.video){
              document.getElementById('video').addEventListener('ended', (event) => {
                  this.$parent.handleVideoFinished();
              });
            }
          }
        },
    }
</script>

<style lang="scss">
@mixin element($element) {
    &__#{$element} {
        @content;
    }
}

// Colours used in silentbox
$main:   #fff;
$accent: #58e8d2;
$bg: #000;


.zoom {
    position: absolute;
    bottom: 1.5rem; /* Aumenta el espacio inferior */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 2.5rem; /* Aumenta el espacio entre los elementos */
    background: rgba(0, 0, 0, 0.7); /* Fondo ligeramente más oscuro */
    padding: 10px 16px; /* Aumenta el padding para hacer el contenedor más grande */
    border-radius: 5px; /* Aumenta el borde redondeado */
    z-index: 9999; /* Z-index suficientemente alto para estar sobre otros elementos */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Sombra un poco más pronunciada */
}

.zoom__container {
    display: flex;
    align-items: center;
    gap: 1rem; /* Aumenta el espacio entre los botones */
}

.zoom__label {
    font-size: 16px; /* Incrementa el tamaño de la fuente */
    font-family: 'Bariol';
    color: #e0e0e0; /* Color del texto en un tono gris claro */
    margin: 0 10px;
    font-weight: 600; /* Aumenta el peso del texto */
}

.zoom__button {
    background-color: transparent; /* Sin fondo para un estilo más limpio */
    color: #e0e0e0;
    font-size: 40px; /* Incrementa el tamaño de la fuente */
    width: 36px; /* Aumenta el tamaño del botón */
    height: 36px; /* Aumenta el tamaño del botón */
    border-radius: 6px; /* Borde más redondeado */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease; /* Transiciones suaves */
}

.zoom__button:hover {
    color: #464141; /* Color del texto oscuro al pasar el ratón */
}

.zoom__button:active {
    border-color: #464141; /* Borde que coincide con el fondo al hacer clic */
}

.zoom__button i {
    font-size: 18px; /* Asegura que el icono se escala adecuadamente */
    color: inherit; /* Hereda el color del botón */
}



.silentbox-is-opened {
    overflow: hidden;
}

#silentbox-overlay {
    display: block;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 9999;

    @include element(background) {
        background: rgba($bg, .75);
        cursor: default;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    @include element(content) {
        cursor: default;
        display: block;
        height: 100%;
        position: relative;
        width: 100%;
    }

    @include element(embed) {
        bottom: 0;
        cursor: default;
        display: block;
        height: 80%;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: -2.5rem;
        width: 75%;


        img{
            cursor: grab; /* Cambia el cursor a "mano" para indicar que la imagen es arrastrable */
            transition: transform 0.3s ease; /* Transición suave para el zoom */
            &.dragging {
                cursor: grabbing; /* Cambia el cursor a "agarrando" cuando se arrastra */
            }
        }
        img,
        iframe {
            background-color: $bg;
            bottom: 0;
            box-shadow: 0 0 1.5rem rgba($bg, .45);
            cursor: default;
            display: block;
            left: 0;
            margin: auto;
            max-height: 100%;
            max-width: 100%;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    @include element(container) {
        cursor: default;
        height: 100%;
        margin: 0 0 1.5rem 0;
        position: relative;
        text-align: center;
        width: 100%;
    }

    @include element(description) {
        display: block;
        padding-top: 1rem;
        text-align: center;
        color: $main;
    }

    @include element(close-button) {
        background: rgba($bg, .0);
        border: none;
        color: $main;
        cursor: pointer;
        height: 3.2rem;
        position: absolute;
        right: 3.2rem;
        top: 3.2rem;
        transition: background-color 250ms ease-out;
        width: 3.2rem;
        &:hover,
        &:focus {
            background-color: rgba($bg, .5);
            outline: none;
        }

        .icon {
            color: $main;
            cursor: pointer;
            height: 1rem;
            left: 0.3rem;
            margin: 50% 50% 0 0;
            position: absolute;
            right: 0px;
            top: -0.5rem;
            transition: 250ms ease;
            width: 2.5rem;
            &:before,
            &:after {
                background: $main;
                content: "";
                height: 2px;
                left: 5%;
                position: absolute;
                top: 50%;
                transition: 250ms ease;
                width: 100%;
            }
            &:before {
                transform: rotate(-45deg);
            }
            &:after {
                transform: rotate(45deg);
            }
            &:hover,
            &:focus {
                &:before,
                &:after {
                    background: $accent;
                    left: 25%;
                    width: 50%;
                }
            }
        }
    }

    @include element(arrow-buttons) {
        .arrow {
            border-left: 2px solid $main;
            border-top: 2px solid $main;
            cursor: pointer;
            height: 1.5rem;
            position: absolute;
            width: 1.5rem;
            &:hover,
            &:focus {
                outline: none;
                border-color: $accent;
            }
        }
        .arrow-previous {
            left: 2rem;
            top: 50%;
            transform: rotate(-45deg);
            &:hover,
            &:focus {
                animation-duration: 1s;
                animation-iteration-count: infinite;
                animation-name: pulsingPrevious;
            }
        }
        .arrow-next {
            right: 2rem;
            top: 50%;
            transform: rotate(135deg);
            &:hover,
            &:focus {
                animation-duration: 1s;
                animation-iteration-count: infinite;
                animation-name: pulsingNext;
            }
        }
    }
}

// Animations
@keyframes pulsingNext {
    0%   {
        animation-timing-function: ease-in;
        right: 2rem;
    }
    25%  {
        animation-timing-function: ease-in;
        right: 1.75rem;
    }
    75%  {
        animation-timing-function: ease-in;
        right: 2.25rem;
    }
    100% {
        animation-timing-function: ease-in;
        right: 2rem;
    }
}
@keyframes pulsingPrevious {
    0%   {
        animation-timing-function: ease-in;
        left: 2rem;
    }
    25%  {
        animation-timing-function: ease-in;
        left: 1.75rem;
    }
    75%  {
        animation-timing-function: ease-in;
        left: 2.25rem;
    }
    100% {
        animation-timing-function: ease-in;
        left: 2rem;
    }
}
</style>
