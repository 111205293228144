<template lang="html">
  <div class="WatUX">
    <iframe
      id="wat-ux-iframe"
      :src="formatedUrl"
      height="100%"
      width="100%"
      frameborder="0">
    </iframe>

    <!-- Notification for required permissions to fix Firefox required interaction -->
    <Modal :title="$t('WatUX.permissions.required')" :show="showPermisionsWarnModal">
      <template v-slot:content>
        <ul>
          <li class="Checkpoint--ListItemInitial" v-if="uxData.recordScreen"><i class="fas fa-desktop"></i> <span>{{ $t('WatUX.screen') }}</span></li>
          <li class="Checkpoint--ListItemInitial" v-if="uxData.recordMicrophone"><i class="fas fa-microphone"></i> <span>{{ $t('WatUX.microphone') }}</span></li>
          <li class="Checkpoint--ListItemInitial" v-if="uxData.recordWebcam">
            <svg class="screenAndCameraRecording__Icon" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 21V19.93C9.33387 19.6891 7.81031 18.856 6.7084 17.5833C5.60648 16.3106 4.99998 14.6835 5 13V8C5 7.08075 5.18106 6.1705 5.53284 5.32122C5.88463 4.47194 6.40024 3.70026 7.05025 3.05025C7.70026 2.40024 8.47194 1.88463 9.32122 1.53284C10.1705 1.18106 11.0807 1 12 1C12.9193 1 13.8295 1.18106 14.6788 1.53284C15.5281 1.88463 16.2997 2.40024 16.9497 3.05025C17.5998 3.70026 18.1154 4.47194 18.4672 5.32122C18.8189 6.1705 19 7.08075 19 8V13C19 14.6835 18.3935 16.3106 17.2916 17.5833C16.1897 18.856 14.6661 19.6891 13 19.93V21H17V23H7V21H11ZM12 3C10.6739 3 9.40215 3.52678 8.46447 4.46447C7.52678 5.40215 7 6.67392 7 8V13C7 14.3261 7.52678 15.5979 8.46447 16.5355C9.40215 17.4732 10.6739 18 12 18C13.3261 18 14.5979 17.4732 15.5355 16.5355C16.4732 15.5979 17 14.3261 17 13V8C17 6.67392 16.4732 5.40215 15.5355 4.46447C14.5979 3.52678 13.3261 3 12 3V3ZM12 9C12.2652 9 12.5196 8.89464 12.7071 8.70711C12.8946 8.51957 13 8.26522 13 8C13 7.73478 12.8946 7.48043 12.7071 7.29289C12.5196 7.10536 12.2652 7 12 7C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8C11 8.26522 11.1054 8.51957 11.2929 8.70711C11.4804 8.89464 11.7348 9 12 9V9ZM12 11C11.2044 11 10.4413 10.6839 9.87868 10.1213C9.31607 9.55871 9 8.79565 9 8C9 7.20435 9.31607 6.44129 9.87868 5.87868C10.4413 5.31607 11.2044 5 12 5C12.7956 5 13.5587 5.31607 14.1213 5.87868C14.6839 6.44129 15 7.20435 15 8C15 8.79565 14.6839 9.55871 14.1213 10.1213C13.5587 10.6839 12.7956 11 12 11Z" fill="currentColor"/>
            </svg>
            <span>{{ $t('WatUX.webcam') }}</span>
          </li>
        </ul>
      </template>
      <template v-slot:footer>
        <button type="button" name="dismissPermissionButton" @click="initiateDesktopRecord()">{{ $t('Testers_shared_notification_modal_understood') }}</button>
      </template>
    </Modal>

    <!-- Notify missing permissions -->
    <Modal :title="$t('WatUX.permissions.errors.nopermissions_title')" :show="showMissingPermissionsModal">
      <template v-slot:content>
        <ul>
          <li class="Checkpoint--ListItem" v-if="uxData.recordScreen && platform == 'desktop'"><i class="far fa-times-circle" v-if="permissions.includes('screen')"></i><i class="far fa-check-circle" v-if="!permissions.includes('screen')"></i><span>{{ $t('WatUX.permissions.errors.screen') }}</span></li>
          <li class="Checkpoint--ListItem" v-if="uxData.recordScreen && platform != 'desktop'"><i class="far fa-times-circle" v-if="permissions.includes('screen')"></i><i class="far fa-check-circle" v-if="!permissions.includes('screen')"></i><span>{{ $t('WatUX.permissions.errors.filesystem') }}</span></li>
          <li class="Checkpoint--ListItem" v-if="uxData.recordMicrophone"><i class="far fa-times-circle" v-if="permissions.includes('microphone')"></i><i class="far fa-check-circle" v-if="!permissions.includes('microphone')"></i><span>{{ $t('WatUX.permissions.errors.microphone') }}</span></li>
          <li class="Checkpoint--ListItem" v-if="uxData.recordWebcam"><i class="far fa-times-circle" v-if="permissions.includes('camera')"></i><i class="far fa-check-circle" v-if="!permissions.includes('camera')"></i><span>{{ $t('WatUX.permissions.errors.camera') }}</span></li>
        </ul>
        <div class="WatUX--ManulBrowser">
          <a :href="getManualForBrowser()" target="_blank" v-html="$t('WatUX.permissions.info')"></a>
        </div>
      </template>
      <template v-slot:footer>
        <button type="button" name="dismissPermissionButton" @click="dismissPermissionModal">{{ $t('WatUX.refresh_mode') }}</button>
      </template>
    </Modal>

    <!-- Display pending checkpoints -->
    <Modal :title="$t('WatUX.checkpoints.pending_title')" :show="showPendingCheckpointsModal">
      <template v-slot:content>
        <ul v-if="checkpoints.length > 0">
          <li v-for="checkpoint in checkpoints" :key="checkpoint.checkId" class="Checkpoint--ListItem">
            <span class="Checkpoint__Icon" :class="checkpoint.completed ? 'Checkpoint__Icon--completed' : 'Checkpoint__Icon--incomplete'">
              <svg xmlns="http://www.w3.org/2000/svg" v-if="!checkpoint.completed" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" width="24" height="24">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" v-else viewBox="0 0 20 20" fill="currentColor" width="24" height="24">
                <path fill-rule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
            </span>
            {{ checkpoint.message }}
          </li>
        </ul>
        <div v-if="!checkpoints || checkpoints.length == 0" v-html="taskDescription"></div>
      </template>
      <template v-slot:footer>
        <button type="button" name="dismissButton" @click="dismissModal">{{ $t('Testers_shared_notification_modal_understood') }}</button>
      </template>
    </Modal>

    <!-- Display data submission in progress -->
    <Modal :title="$t('WatUX.submit.title')" :show="showDataSubmissionModal">
      <template v-slot:content>
        <ul>
          <li v-if="this.uxData.recordScreen" class="WatUX--UploadProgress">
            <span v-if="!screenRecordSent && !screenRecordError">{{ $t('WatUX.submit.store.screen.process') }}</span>
            <span v-if="screenRecordError">{{ $t('WatUX.submit.errors.store.screen') }}</span>
            <span v-else-if="screenRecordSent">{{ $t('WatUX.submit.store.screen.success') }}</span>
            <div class="WatUX--UploadProgressBar">
              <div class="WatUX--UploadProgressCount" :style="{'width':screenUploadProgress+'%'}"></div>
            </div>
            <span class="WatUX--UploadProgressNum">{{screenUploadProgress}}%</span>
          </li>
          <li v-if="this.uxData.recordWebcam" class="WatUX--UploadProgress">
            <span v-if="!webcamRecordSent && !webcamRecordError">{{ $t('WatUX.submit.store.camera.process') }}</span>
            <span v-if="webcamRecordError">{{ $t('WatUX.submit.errors.store.camera') }}</span>
            <span v-else-if="webcamRecordSent">{{ $t('WatUX.submit.store.camera.success') }}</span>
            <div class="WatUX--UploadProgressBar">
              <div class="WatUX--UploadProgressCount" :style="{'width':webcamUploadProgress+'%'}"></div>
            </div>
            <span class="WatUX--UploadProgressNum">{{webcamUploadProgress}}%</span>
          </li>
        </ul>
      </template>
    </Modal>

    <!-- Notify errors on data submission -->
    <Modal :title="$t('WatUX.checkpoints.pending_title')" :show="showPendingDataModal">
      <template v-slot:content>
        <div v-if="pendingData['error']">
          {{ $t('WatUX.submit.errors.generic') }}
        </div>
        <ul>
          <li v-if="!pendingData['screenVideoReady']">{{ $t('WatUX.submit.errors.store.screen') }}</li>
          <li v-if="!pendingData['webcamVideoReady']">{{ $t('WatUX.submit.errors.store.camera') }}</li>
          <li v-for="checkpoint in pendingData['pendingCheckpoints']" :key="checkpoint.checkId">
            <span v-if="checkpoint.completed">✓</span>
            <span v-if="!checkpoint.completed">✗</span>
            {{ checkpoint.message }}
          </li>
        </ul>
      </template>
      <template v-slot:footer>
        <button type="button" name="dismissPermissionButton" @click="dismissPermissionModal">{{ $t('WatUX.refresh_mode') }}</button>
      </template>
    </Modal>
  </div>
</template>

<script>
import store from '@/store'
import Modal from '../Modal/Modal.vue'

import { mapGetters } from 'vuex';
import { startUXRecorder, checkDeniedPermissions, stopUXRecorder, getStoredRecordings, resetBlobRecordings } from '../../utils/ux-recorder';
export default {
  name: 'WatUX',

  props: {
    isEmbeddedTest:{
      type: Boolean,
      default: false
    },
    url:{
      type: String,
      default: ""
    },
    taskTestId:{
      type: Number,
      default: 1,
    },
    taskNum:{
      type: Number,
      default: 1,
    },
    uxData:{
      type: Object,
      default: {},
    },
    testId:{
      type: String,
      default: '0b2277db-3c4e-4847-b2f3-d27c9bd0b1bb-4366',
    },
    taskDescription: {
      type: String,
      default: null
    },
    UxWithoutCheckpoints: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Modal
  },

  data(){
    return{
      platform                    : 'desktop',
      webTestIframeMinTime        : 20,

      checkpoints                 : [],
      pendingData                 : [],
      permissions                 : [],
      completedCheckpoints        : {},
      
      interval                    : '',
      checkpointUrl               : '',
      audioRecordUrl              : '',
      screenRecordUrl             : '',
      videoStorageUrl             : '',
      webcamRecordUrl             : '',
      dataValidationUrl           : '',
      
      audioRecordSent             : false,
      screenRecordSent            : false,
      webcamRecordSent            : false,
      audioRecordError            : false,
      screenRecordError           : false,
      webcamRecordError           : false,

      showPendingDataModal        : false,
      showBackgroundRecovery      : false,
      showDataSubmissionModal     : false,
      showPermisionsWarnModal     : false,
      showMissingPermissionsModal : false,
      showPendingCheckpointsModal : false,

      audioUploadProgress         : 0,
      screenUploadProgress        : 0,
      webcamUploadProgress        : 0,
    }
  },

  computed: {
    pendingCheckpoints() {
      for (let checkpoint of this.checkpoints) {
        if (checkpoint.completed == false) {
          return true;
        }
      }

      return false;
    },
    formatedUrl(){
      if(!this.url?.includes('figma') || this.url?.includes('embed')) return this.url
      return "https://www.figma.com/embed?embed_host=share&url=" + this.url
    }
  },
  
  async created() {
    this.assignUrls();
    this.assignCheckpoints();
    this.checkCurrentPlatform();
    this.WatUXAddEventListeners();
  },

  async unmounted() {
    this.WatUXRemoveEventListeners();
  },

  async mounted() {
    if(this.UxWithoutCheckpoints){
      this.showPendingCheckpointsModal = true
    }
    this.intializeWatUX();
  },

  methods: {
    // Setup process
    assignCheckpoints() {
      if (!Object.keys(this.uxData).includes('checkpoints')) {
        return;
      }

      let checkpointCount = this.uxData.checkpoints.length;

      for (let i = 0; i < checkpointCount; i++) {
        this.uxData.checkpoints[i].completed = false;
      }

      this.checkpoints = this.uxData.checkpoints;
    },

    assignUrls() {
      let urlPrefix = isNaN(this.testId) ? '/public/test/' : '/test/';
      
      this.checkpointUrl     = urlPrefix + this.testId + '/task/' + this.taskNum + '/uxCheckpointCompleted';
      this.dataValidationUrl = urlPrefix + this.testId + '/task/' + this.taskNum + '/uxDataValidation';
      this.videoStorageUrl   = urlPrefix + this.testId + '/task/' + this.taskNum + '/uxVideos';
    },

    checkCurrentPlatform() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/windows phone/i.test(userAgent)) {
        this.platform = 'windowsphone';
      }

      if (/android/i.test(userAgent)) {
        this.platform = 'android';
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        this.platform = 'ios';
      }
    },

    WatUXAddEventListeners(){
      window.addEventListener('message', this.handleWatUXEvent);

        if (this.platform != 'desktop') {
          window.addEventListener('watux-permissions-granted', this.videoRecorderInitialize)
          window.addEventListener('watux-video-recorder-initialized', this.initiateMobileRecord);
          window.addEventListener('watux-progress-event', this.handleCapacitorUploadProgressEvent);
          window.addEventListener('watux-store-record-result', this.handleCapacitorStoreResultsEvent);
          window.addEventListener('watux-notify-record-insertion-complete', this.handleCapacitorCompletionNotificationEvent);
          window.addEventListener('watux-data-validation-complete', this.handleCapacitorDataValidationEvent);
          window.addEventListener('watux-background-recovered', this.handleCapacitorBackgroundRecoveredEvent);
        }
    },
    WatUXRemoveEventListeners(){
      window.removeEventListener('message', this.handleWatUXEvent);
        
        if (this.platform != 'desktop') {
          window.removeEventListener('watux-permissions-granted', this.videoRecorderInitialize)
          window.removeEventListener('watux-video-recorder-initialized', this.initiateMobileRecord);
          window.removeEventListener('watux-progress-event', this.handleCapacitorUploadProgressEvent);
          window.removeEventListener('watux-store-record-result', this.handleCapacitorStoreResultsEvent);
          window.removeEventListener('watux-notify-record-insertion-complete', this.handleCapacitorCompletionNotificationEvent);
          window.removeEventListener('watux-data-validation-complete', this.handleCapacitorDataValidationEvent);
          window.removeEventListener('watux-background-recovered', this.handleCapacitorBackgroundRecoveredEvent);
        }
    },
    requestPermissions(){
      let event = new CustomEvent('watux-request-permissions', { 
      detail : {
          devices  : {
            camera     : this.uxData.recordWebcam,
            screen     : this.uxData.recordScreen, 
            microphone : this.uxData.recordMicrophone, 
          },
        }
      });

      window.dispatchEvent(event);
    },
    // Process start
    intializeWatUX() {
      if(!this.needsRecordingAnyDevice()) return
      return this.platform == 'desktop' ? this.showPermissionsWarning() : this.requestPermissions();
    },

    // Desktop specific events flow
    async showPermissionsWarning() {
      await this.checkDeniedPermissions();
      this.showPermisionsWarnModal = true;
    },

    async checkDeniedPermissions() {
      let permissions = null;
      
      await checkDeniedPermissions()
        .then((result) => {
          permissions = result;
        });

      this.permissions = permissions;
    },

    showHidePermissionModal(value){
      this.showMissingPermissionsModal = value;
    },

    async initiateDesktopRecord() {
      this.showPermisionsWarnModal = false;

      let recordingStarted = await startUXRecorder(this.uxData.recordScreen, this.uxData.recordMicrophone, this.uxData.recordWebcam);

      if (!recordingStarted) {
        await this.checkDeniedPermissions();
        this.showHidePermissionModal(true);
      }

      this.screenRecordSent = !this.uxData.recordScreen;
      this.webcamRecordSent = !this.uxData.recordWebcam;

      this.$emit('notifyRecording', this.uxData.recordScreen, this.uxData.recordWebcam);
    },

    dispatchDesktopNotifyCompletion(data) {
      store.dispatch('NOTIFY_COMPLETION', data)
      .catch((error) => {
        console.log(error);
      });
    },

    stopWatUxRecord(){
      stopUXRecorder();
    },

    stopDesktopRecord() {
      stopUXRecorder();
      this.storeDesktopRecordings();
    },

    storeDesktopRecordings() {
      this.showDataSubmissionModal = true;

      var recordings = getStoredRecordings();

      if (!recordings) {
        if (this.interval == '') {
          this.interval = setInterval(() => {
            this.storeDesktopRecordings()
          }, 1000);
        }
        return;
      }
      clearInterval(this.interval);

      if (recordings.screenFile != '' && !this.screenRecordSent) {
        var data = {
          testId: this.testId,
          file: recordings.screenFile,
          onUploadProgress: progressEvent => {
            this.screenUploadProgress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          },
        };

        store.dispatch('STORE_TEST_RECORDINGS', data)
          .then((response) => {
            this.screenRecordSent = true;
            this.screenRecordUrl = response.path;
          })
          .catch((error) => {
            this.screenRecordSent = true;
            this.screenRecordError = true;
            console.log(error);
          })
      }

      if (recordings.cameraFile != '' && !this.webcamRecordSent) {
        var data = {
          testId: this.testId,
          file: recordings.cameraFile,
          onUploadProgress: progressEvent => {
            this.webcamUploadProgress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          },
        };

        store.dispatch('STORE_TEST_RECORDINGS', data)
          .then((response) => {
            this.webcamRecordSent = true;
            this.webcamRecordUrl = response.path;
          })
          .catch((error) => {
            this.webcamRecordSent = true;
            this.webcamRecordError = true;
            console.error(error);
          });
      }

      this.interval = '';
      resetBlobRecordings();
      
      this.notifyRecordInsertion();
    },

    dispatchDesktopNotifyRecordInsertion(data) {
      store.dispatch('NOTIFY_COMPLETION', data)
      .then((response) => {
        this.showDataSubmissionModal = false;
        this.dispatchDesktopDataValidation();
      })
      .catch((error) => {
        console.log(error);
      });
    },

    dispatchDesktopDataValidation() {
      store.dispatch('VALIDATE_DATA_COMPLETION', {testId: this.testId, url: this.dataValidationUrl})
      .then((response) => {
        this.processDataValidationResponse(response)
      })
      .catch((error) => {
        this.pendingData['error'] = true;
        this.showPendingDataModal = true;
        console.log(error)
      });
    },

    // Mobile specific events flow
    videoRecorderInitialize() {
      let event = new CustomEvent('watux-recording', { 
        detail : {
          platform : this.platform, 
          action   : 'prepare',
        }
      });

      window.dispatchEvent(event);
    },
    initiateMobileRecord(eventResponse) {
      let values = eventResponse.detail;

      let event = new CustomEvent('watux-recording', { 
        detail : {
          platform : this.platform, 
          action   : 'start',
          devices  : {
            camera     : this.uxData.recordWebcam,
            screen     : this.uxData.recordScreen, 
            microphone : this.uxData.recordMicrophone, 
          },
          initialized : values.intialized
        }
      });

      window.dispatchEvent(event);

      this.screenRecordSent = !this.uxData.recordScreen;
      this.webcamRecordSent = !this.uxData.recordWebcam;
    },

    dispatchMobileNotifyCompletion(data) {
      let event = new CustomEvent('watux-checkpoint-completion', { 
        detail : data
      });

      window.dispatchEvent(event);
    },

    stopMobileRecord() {
      let event = new CustomEvent('watux-recording', { 
        detail : {
          platform : this.platform, 
          action   : 'stop',
          devices  : {
            camera     : this.uxData.recordWebcam,
            screen     : this.uxData.recordScreen, 
            microphone : this.uxData.recordMicrophone, 
          }
        }
      });

      this.showDataSubmissionModal = true;
      window.dispatchEvent(event);
    },

    handleCapacitorUploadProgressEvent(event) {
      let values = event.detail;

      if (values.source == 'audio') {
        this.audioUploadProgress = Math.floor((values.progressEvent.loaded * 100) / values.progressEvent.total);
      }

      if (values.source == 'screen') {
        this.screenUploadProgress = Math.floor((values.progressEvent.loaded * 100) / values.progressEvent.total);
      }

      if (values.source == 'webcam') {
        this.webcamUploadProgress = Math.floor((values.progressEvent.loaded * 100) / values.progressEvent.total);
      }
    },

    handleCapacitorStoreResultsEvent(event) {
      let results = event.detail;

      if (results.source == 'audio') {
        this.audioRecordSent = true;
        this.audioRecordUrl = results.error ? '' : results.path;
        this.audioRecordError = results.error;
      }

      if (results.source == 'screen') {
        this.screenRecordSent = true;
        this.screenRecordUrl = results.error ? '' : results.path;
        this.screenRecordError = results.error;
      }

      if (results.source == 'webcam') {
        this.webcamRecordSent = true;
        this.webcamRecordUrl = results.error ? '' : results.path;
        this.webcamRecordError = results.error;
        console.error(results.error);
      }

      if (this.screenRecordSent && this.webcamRecordSent) {
        this.notifyRecordInsertion();
      }
    },
    
    dispatchMobileNotifyRecordInsertion(data) {
      let event = new CustomEvent('watux-notify-record-insertion', { 
        detail : data
      });

      window.dispatchEvent(event);
    },

    handleCapacitorCompletionNotificationEvent(event) {
      this.showDataSubmissionModal = false;
      this.dispatchMobileDataValidation();
    },

    dispatchMobileDataValidation() {
      let event = new CustomEvent('watux-data-validation', { 
        detail : {
          testID : this.testId, 
          url: this.dataValidationUrl
        }
      });

      window.dispatchEvent(event);
    },

    handleCapacitorDataValidationEvent(event) {
      let response = event.detail.response;
      console.log(Object.keys(response).includes('error'));
      

      if (Object.keys(response).includes('error')) {
        this.pendingData['error'] = true;
        this.showPendingDataModal = true;
        return;
      }

      this.processDataValidationResponse(response);
    },

    handleCapacitorBackgroundRecoveredEvent(event) {
      this.showBackgroundRecovery = true;
    },

    // Platform common methods
    handleWatUXEvent(event) {
      switch (event.data.type) {
        case 'pageLoaded':
          this.registerWatClientEventListeners();
          this.processPageLoadedEvent(event.data);
          break;
        case 'checkpointCompleted':
          this.processCheckpointCompletedEvent(event.data);
          break;
        default:
          // no event available
          break;
      }
    },

    registerWatClientEventListeners() {
      let checkpointCount = this.checkpoints.length;

      for (let i = 0; i < checkpointCount; i++) {
        document.getElementById('wat-ux-iframe').contentWindow.postMessage(JSON.parse(JSON.stringify(this.checkpoints[i])), '*');
      }
    },

     processPageLoadedEvent(data) {
      const navigationEvents = [
        'url_load',
      ];

      this.checkpoints.filter(checkpoint => {
        if (navigationEvents.includes(checkpoint.type.toLowerCase()) && checkpoint.checkUrl == data.url) {
          checkpoint.completed = true;
          
          return this.markCheckpointAsCompleted(checkpoint);
        }

        return false;
      });
    },

    processCheckpointCompletedEvent(data) {
      this.checkpoints.filter(checkpoint => {
        if (checkpoint.checkId == data.checkId) {
          checkpoint.completed = data.completed;

          return this.markCheckpointAsCompleted(checkpoint);
        }

        return false;
      });
    },

    markCheckpointAsCompleted(checkpoint) {
      if (!Object.keys(this.completedCheckpoints).includes(checkpoint.checkId)) {
        this.completedCheckpoints[checkpoint.checkId] = {
          checkId : checkpoint.checkId,
          completedDate: new Date().toISOString(),
        };

        this.notifyCheckpointCompletion();
      }

      return true;
    },

    notifyCheckpointCompletion() {
      var data = {
        url: this.checkpointUrl,
        testId: this.testId,
        checkpointsCompleted: [],
      };

      for (let checkpoint in this.completedCheckpoints) {
        data.checkpointsCompleted.push(this.completedCheckpoints[checkpoint]);
      }

      this.platform == 'desktop' ? this.dispatchDesktopNotifyCompletion(data) : this.dispatchMobileNotifyCompletion(data);
    },

    async sendToQuestions() {
      if (this.pendingCheckpoints) {
        this.showPendingCheckpointsModal = true;
        return;
      }
      this.$emit('notifyRecording', false, false);
      if(!this.needsRecordingAnyDevice()) {
        this.$emit('continue'); 
        return
      }
      this.platform == 'desktop' ? this.stopDesktopRecord() : this.stopMobileRecord();
    },

    needsRecordingAnyDevice(){
      return this.uxData.recordMicrophone || this.uxData.recordScreen || this.uxData.recordWebcam
    },

    notifyRecordInsertion() {
      if (!this.webcamRecordSent || !this.screenRecordSent) {
        if (this.interval == '') {
          this.interval = setInterval(() => {
            this.notifyRecordInsertion()
          }, 1000);
        }
        return;
      }
      clearInterval(this.interval);

      var data = {
        url: this.videoStorageUrl,
        testId: this.testId,
        audioUrl: this.audioRecordSent ? this.audioRecordUrl : '',
        screenVideoUrl: this.screenRecordUrl,
        webcamVideoUrl: this.webcamRecordUrl,
      };
      
      this.platform == 'desktop' ? this.dispatchDesktopNotifyRecordInsertion(data) : this.dispatchMobileNotifyRecordInsertion(data);
    },

    processDataValidationResponse(response) {
      if (response.pendingCheckpoints.length < 1 && response.screenVideoReady && response.webcamVideoReady) {
        this.$emit('continue');
      } else {
        this.pendingData['screenVideoReady']   = response.screenVideoReady;
        this.pendingData['webcamVideoReady']   = response.webcamVideoReady;
        this.pendingData['pendingCheckpoints'] = response.pendingCheckpoints;

        this.showPendingDataModal = true;
      }
    },

    // Other methods
    async iframeTimer(){
      if (this.webTestIframeMinTime > 0) {
        this.webTestIframeMinTime= this.webTestIframeMinTime -1
        setTimeout(() => this.iframeTimer(), 1000);
      }
    },

    dismissModal() {
      this.showPendingCheckpointsModal = false;
    },

    dismissPermissionModal() {
      location.reload();
    },

    getManualForBrowser() {
      let isFirefox = typeof InstallTrigger !== 'undefined';
      let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
      let isIE = /*@cc_on!@*/false || !!document.documentMode;
      let isEdge = !isIE && !!window.StyleMedia;
      let isChrome = navigator.userAgent.includes("Chrome") && navigator.vendor.includes("Google Inc");
      if (isFirefox) return "https://support.mozilla.org/es/kb/como-administrar-los-permisos-de-tu-camara-y-micro"
      if (isSafari) return "https://support.apple.com/es-es/guide/safari/ibrw7f78f7fe/mac"
      if (isEdge) return "https://support.microsoft.com/es-es/help/4468232/windows-10-camera-microphone-and-privacy"
      if (isChrome) return "https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=es-419"
    },
    toggleShowPendingCheckpointsModal(){
      this.showPendingCheckpointsModal = !this.showPendingCheckpointsModal
    }
  },
}
</script>


<style lang="scss">
.WatUX{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  iframe{
    padding: 5px;
    border-radius: 6px;
    background-color: white;
    width: 100%;
  }
  button{
    background: var(--button-color);
    font-family: 'Bariol';
    font-weight: 600;
    font-size: 16px;
    border-radius: 3px;
    border: none;
    padding: .8rem 2rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color:white;
    margin: 10px 0;
    transition: background .2s ease-in-out;
    max-width: 100%;
    &:hover {
      background: var(--contrast-color);
    }
    &:disabled{
      opacity: 0.5;
    }
  }
  &--ManulBrowser{
    margin-top: 20px;
    a{
      color: black;
      text-decoration: none;
    }
    b{
      color: #8134ff;
    }
  }
  &--UploadProgress{
    position: relative;
  }
  &--UploadProgressBar{
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: 0;
    background: lightgray;
    border-radius: 3px;
  }
  &--UploadProgressCount{
    height: 3px;
    transition:0.5s;
    background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
  }
  &--UploadProgressNum{
    position: absolute;
    right: 9px;
    font-weight: 700;
  }
}
.Checkpoint {
  &--ListItem{
    display: grid!important;
    justify-content: flex-start!important;
    grid-template-columns: 50px auto;
    text-align: justify;
    .fa-check-circle{
      color: #96c800;
    }
    .fa-times-circle{
      color: red;
    }
  }
  &--ListItemInitial{
    display: grid!important;
    grid-template-columns: 23px 72px;
    text-align: initial;
    i,svg{
      margin-right: 5px;
    }
  }
  &__Icon {
    &--incomplete {
      color: rgba(#000,0.3);
    }
    &--completed {
      color: var(--button-color)
    }
  }
}
</style>
