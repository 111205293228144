
import * as yup from 'yup'
import { useField } from 'vee-validate'
import { useRouter } from 'vue-router'
import { defineComponent, ref, Ref, inject, ComputedRef, computed } from 'vue'
import testsStore from '@/store/tests.store'
import {
    IonContent,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonPage,
    IonInput,
    IonItem,
    IonLabel,
    IonButton,
} from '@ionic/vue'
import Swal from 'sweetalert2'
import { useI18n } from "vue-i18n";
import i18n from '@/i18n';
import { Preferences } from '@capacitor/preferences';
import authStore from '@/store/auth.store'
export default defineComponent({ 
    name: 'validate2FA', 
    components:{
        IonContent,
        IonCard,
        IonCardContent,
        IonCardHeader,
        IonPage,
        IonInput,
        IonItem,
        IonLabel,
        IonButton,
    },
    data(){
        return{
            code2fa:'',
            mobileNum: ''
        }
    },
    computed:{
        formValid(){
            return this.code2fa != null && this.code2fa.length > 3
        },
        getCurrentYear(){
            return new Date().getFullYear()
        }
    },
    methods:{
        async validate(){
            const validation = await authStore.validate2faCode({code2fa:this.code2fa})
            if(validation) {
                Preferences.remove({key:'needs2FA'})
                await authStore.getUserDetails();
                await testsStore.getTests(0);
                this.$router.push({name:'tests.index'})
            }
            else {
                Swal.fire("",this.$t(authStore.state.send2fa.errorMessage),"error")
            }
        },
        async resendCode(){
            const validation = await authStore.resend2facode()
            Swal.fire("",this.$t('tester_mobile_validation_resend'),"success")
        }
    },
    async beforeMount() {
        await authStore.getMobileFor2FA()
        this.mobileNum = authStore.state.userData.mobileNum;
        this.mobileNum = '******' + this.mobileNum.slice(-3);
    }
})
